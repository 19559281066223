// ==========================================================================
// Delivery Info Search Function JS Module
// ==========================================================================


define(['app', 'deliveryInfoAliases'], function(app, deliveryInfoAliases) {

  class DeliveryInfoSearch {

    constructor(classNamePrefix) {
      this.init = this.init.bind(this);
      this.search = this.search.bind(this);
      this.showErrorMessage = this.showErrorMessage.bind(this);

      classNamePrefix = classNamePrefix || 'deliveryInfoSearch_';

      this.classNames = {
        searchInput: `${classNamePrefix}searchInput`,
        searchButton: `${classNamePrefix}searchButton`,
        selectedTable: `${classNamePrefix}selected`,
        selectedAccordion: `${classNamePrefix}selectedAccordion`,
        errorMessage: `${classNamePrefix}errorMessage`,
        errorMessageShow: `${classNamePrefix}errorMessage-show`
      };
    }

    init(element) {
      this.containerElement = element;
      this.errorMessage = element.getElementsByClassName(this.classNames.errorMessage)[0];
      this.searchInput = element.getElementsByClassName(this.classNames.searchInput)[0];
      const searchButton = element.getElementsByClassName(this.classNames.searchButton)[0];

      if (this.searchInput && searchButton) {
        searchButton.addEventListener('click', this.search);
      }
    }


    search(event) {
      event.preventDefault();


      this.showErrorMessage(false);

      if (this.selectedNamedElements) {
        for (let i = 0; i < this.selectedNamedElements.length; i += 1) {
          const namedElement = this.selectedNamedElements[i];
          if (namedElement.type === 'TABLE') {
            namedElement.element.style.border = 'none';
            app.element.removeClass(this.classNames.selectedTable, namedElement.element);
          } else {
            app.element.removeClass(this.classNames.selectedAccordion, namedElement.element);
          }

        }
        this.selectedNamedElements = undefined;
      }

      this.selectedNamedElements = this.getElementsMatching(this.searchInput.value)

      if (!this.selectedNamedElements.length) {
        this.showErrorMessage(true);
      } else {
        for (let i = 0; i < this.selectedNamedElements.length; i += 1) {
          const namedElement = this.selectedNamedElements[i];

          if (namedElement.type === 'TABLE') {
            app.element.addClass(this.classNames.selectedTable, namedElement.element);
            namedElement.element.style.border = '1px solid black';
          } else {
            app.element.addClass(this.classNames.selectedAccordion, namedElement.element);
          }
        }
        const targetElement = this.selectedNamedElements[0].element;
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        
        let targetButton = targetElement.getElementsByTagName('button')[0];
        if (targetButton) {
          targetButton.style.textDecoration = 'underline';
          targetButton.click();
          targetButton.addEventListener('click', function() {
            this.style.textDecoration = '';
          });
          // Wait for scroll then focus on result accordion for keyboard users
          setTimeout(() => targetButton.focus(), 1000);
        }
        
      }
    }

    getElementsMatching(searchStr) {
      searchStr = searchStr.toLowerCase();
      if (!searchStr) {
        return Array();
      }

      const tableElements = Array.from(this.containerElement.querySelectorAll('tr')).filter(function(row){return (row.querySelector('td'));});
      const accordionElements = Array.from(this.containerElement.querySelectorAll('div.accordionWidget')).filter(function(row){return (row.querySelector('button'));});
      const namedTableElements = tableElements.map(function(element){
        return {
          name: element.querySelector('td').innerText.toLowerCase(),
          aliases: deliveryInfoAliases[DeliveryInfoSearch.sanitizeId(element.id)] || [],
          element: element,
          type: 'TABLE'
        };});

      const namedAccordionElements = accordionElements.map(function(element){
        return {
          name: element.querySelector('button').innerText.toLowerCase(),
          aliases: deliveryInfoAliases[DeliveryInfoSearch.sanitizeId(element.querySelector('button').innerText.toLowerCase())] || [],
          element: element,
          type: 'ACCORDION'
        };});

      const namedElements = namedTableElements.concat(namedAccordionElements);
      if (searchStr.length == 1)
      {
        return namedElements.filter(function(namedElement) {
          return ((namedElement.name.indexOf(searchStr) === 0) || (namedElement.aliases.filter(function (alias){return (alias.toLowerCase().indexOf(searchStr) === 0)}).length > 0))
        });
      }
      return namedElements.filter(function(namedElement) {
        return ((namedElement.name.indexOf(searchStr) !== -1) || (namedElement.aliases.filter(function (alias){return (alias.toLowerCase().indexOf(searchStr) !== -1)}).length > 0))
      });
    }

    showErrorMessage(isShow) {
      if (this.errorMessage) {
        if (isShow) {
          app.element.addClass(this.classNames.errorMessageShow, this.errorMessage);
          this.errorMessage.style.display = 'block';
        } else {
          app.element.removeClass(this.classNames.errorMessageShow, this.errorMessage);
          this.errorMessage.style.display = 'none';
        }
      }
    }

    static sanitizeId(id) {
      if (!id) {
        return undefined;
      }
      return id.replace(/\s+/g, '-').toLowerCase();
    }

  }

  return DeliveryInfoSearch;

});
